
import 'highlight.js/styles/a11y-dark.css';

import hljs from 'highlight.js/lib/core';

import hljsXML from 'highlight.js/lib/languages/xml';

import type { PropType } from 'vue';

import {
  defineComponent,
  ref,
  computed,
} from 'vue';

import type {
  Option,
  Endpoint,
} from '@/types';

import {
  REGIONS_MAP,
  PROTOCOL_DIRECT_LINK,
  TRAFFIC_TYPE_PUSH,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useClipboard from '@/composable/useClipboard';

hljs.registerLanguage('xml', hljsXML);

export default defineComponent({
  props: {
    endpoint: {
      type: Object as PropType<Endpoint>,
      required: true,
    },
    showCompiledName: {
      type: Boolean,
      default: false,
    },
    showCompiledNameAsLink: {
      type: Boolean,
      default: false,
    },
    showUuid: {
      type: Boolean,
      default: false,
    },
    showComment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { activeUser: user } = useUser();

    const { copyToClipboard } = useClipboard();

    const endpointURL = computed((): string => {
      let url = '';

      switch (props.endpoint.protocol) {
        case PROTOCOL_DIRECT_LINK:
          // TODO: Вынести домен abpsl23.com в настройки проекта
          url = `https://abpsl23.com/endpoint?endpoint_uuid=${props.endpoint.uuid}&subid={subid}`;
          break;
        default:
          url = `http://${REGIONS_MAP[props.endpoint.region].subdomain}${props.endpoint.project.host}/endpoint?endpoint_uuid=${props.endpoint.uuid}&ip={ip}&user_agent={user_agent}&subid={subid}&referrer={referrer}&timeout={timeout}`;
      }

      return url;
    });

    const endpointURLExample = computed((): string => {
      let url = endpointURL.value;

      const macros = {
        '{ip}': '66.176.66.154',
        '{user_agent}': encodeURIComponent('Mozilla/5.0 (Linux; Android 8.0.0; SM-G960F Build/R16NW) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36'),
        '{subid}': 'default',
        '{referrer}': encodeURIComponent(`https://${props.endpoint.project.host}`),
        '{timeout}': '200',
      };

      Object.entries(macros).forEach(([key, value]) => {
        url = url.replaceAll(key, value);
      });

      return url;
    });

    const popCodeModes: Option[] = [
      {
        value: 'popup',
        label: 'Popup',
      },
      {
        value: 'popunder',
        label: 'Popunder',
      },
    ];

    const popCodeMode = ref<string>(popCodeModes[0].value as string);

    // TODO: Вынести домен abpjs23.com в настройки проекта (добавить регион?)
    const popCode = computed((): string => `<script \n\tsrc="https://abpjs23.com/dist/js/p.js" \n\tdata-endpoint-uuid="${props.endpoint.uuid}" \n\tdata-subid="default" \n\tdata-mode="${popCodeMode.value}" \n\tdefer><\/script>`);

    const popCodeHeightlighted = computed((): string => hljs.highlight(popCode.value, { language: 'html' }).value);

    // TODO: Вынести домен abpjs23.com в настройки проекта (добавить регион?)
    const pushCode = computed((): string => `<script \n\tsrc="https://${props.endpoint.project.push_host}/dist/js/push.js?version=0.1" \n\tdata-api-url="https://${props.endpoint.project.push_host}/api/subscription/storeSubscription" \n\tdata-service-worker-url="/push-service-worker.js?version=0.1" \n\tdata-endpoint-uuid="${props.endpoint.uuid}" \n\tdata-subid="default" \n\tasync><\/script>`);

    const pushCodeHeightlighted = computed((): string => hljs.highlight(pushCode.value, { language: 'html' }).value);

    const verificationMetaTag = computed((): string => `<meta name="adbison-verification" content="${props.endpoint.uuid}">`);

    const verificationMetaTagHeightlighted = computed((): string => hljs.highlight(verificationMetaTag.value, { language: 'html' }).value);

    const downloadPushServiceWorker = (): void => {
      const blob = new Blob([`importScripts('https://${props.endpoint.project.push_host}/dist/js/push-service-worker.js?version=0.1');`], { type: 'application/javascript' });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.setAttribute('href', url);

      a.setAttribute('download', 'push-service-worker.js');

      a.click();
    };

    return {
      PROTOCOL_DIRECT_LINK,
      TRAFFIC_TYPE_PUSH,
      user,
      copyToClipboard,
      endpointURL,
      endpointURLExample,
      popCodeModes,
      popCodeMode,
      popCode,
      popCodeHeightlighted,
      pushCode,
      pushCodeHeightlighted,
      verificationMetaTag,
      verificationMetaTagHeightlighted,
      downloadPushServiceWorker,
    };
  },
});
